import React from "react";
import {
	FaProjectDiagram,
	FaHardHat,
	FaTruck,
	FaDollarSign,
	FaUsersCog,
	FaCogs,
} from "react-icons/fa";
import Section from "../shared/Section";

const useCasesData = [
	{
		title: "For Project Managers",
		icon: <FaProjectDiagram className="text-gray-800" />,
		description:
			"Gain visibility over the entire project, assign tasks, and track progress in real-time.",
		gradientColors: "from-blue-50 to-green-50",
	},
	{
		title: "For Site Supervisors",
		icon: <FaHardHat className="text-gray-800" />,
		description:
			"Seamlessly update daily activities, upload progress photos, and manage on-site tasks.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
	{
		title: "For Procurement Teams",
		icon: <FaTruck className="text-gray-800" />,
		description:
			"Manage materials more efficiently, track inventory, and minimize procurement delays.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		title: "For Finance Teams",
		icon: <FaDollarSign className="text-gray-800" />,
		description:
			"Gain insights into material costs, work order expenses, and labor costs to stay within budget.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		title: "For Contractors",
		icon: <FaUsersCog className="text-gray-800" />,
		description:
			"Stay updated on work orders, manage sub-contractors, and monitor project progress.",
		gradientColors: "from-red-50 to-yellow-50",
	},
	{
		title: "For Admins",
		icon: <FaCogs className="text-gray-800" />,
		description:
			"Control access to the platform with role-based permissions and monitor overall project status.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
];

const UseCases = () => {
	return (
		<Section
			sectionTitle="How Supersite Empowers Your Team"
			sectionDescription="Discover how Supersite helps different roles in your construction team streamline their tasks and enhance collaboration."
			featuresData={useCasesData}
		/>
	);
};

export default UseCases;
