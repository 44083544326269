import React from "react";
import {
	FaTasks,
	FaCubes,
	FaDollarSign,
	FaUserClock,
	FaFileAlt,
	FaUsersCog,
	FaChartBar,
	FaBell,
	FaTachometerAlt,
} from "react-icons/fa";
import Section from "../shared/Section";

const featuresData = [
	{
		icon: <FaTasks />,
		title: "Task Management",
		description:
			"Assign and manage tasks, track progress, and capture photos for clear accountability.",
		gradientColors: "from-blue-50 to-green-50",
	},
	{
		icon: <FaCubes />,
		title: "Materials Management",
		description:
			"Track inventory in real time, manage material requests, and monitor site issues effectively.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
	{
		icon: <FaDollarSign />,
		title: "Finance Management",
		description:
			"Monitor costs for materials, labor, and work orders, ensuring detailed financial oversight.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		icon: <FaUserClock />,
		title: "Labor Attendance",
		description:
			"Track attendance efficiently and manage absences to ensure smooth operations.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		icon: <FaFileAlt />,
		title: "Project Files & Drawings Management",
		description:
			"Store and manage all project documents centrally with version control.",
		gradientColors: "from-indigo-50 to-purple-50",
	},
	{
		icon: <FaUsersCog />,
		title: "Roles & Access Management",
		description:
			"Assign roles, permissions, and custom access levels for different team members.",
		gradientColors: "from-red-50 to-yellow-50",
	},
	{
		icon: <FaChartBar />,
		title: "Reports",
		description:
			"Generate daily, weekly, and monthly reports, custom-tailored to project needs.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		icon: <FaBell />,
		title: "Notifications",
		description:
			"Stay updated with real-time alerts and notifications for tasks and project milestones.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		icon: <FaTachometerAlt />,
		title: "Dashboard",
		description:
			"Access real-time, user-friendly dashboards that centralize key project metrics.",
		gradientColors: "from-red-50 to-yellow-50",
	},
];

const Features = () => {
	return (
		<Section
			sectionTitle="Key Features for Real Estate"
			sectionDescription="Discover the essential tools to manage real estate construction projects efficiently with Supersite."
			featuresData={featuresData}
		/>
	);
};

export default Features;
