import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUserShield,
	faProjectDiagram,
	faHardHat,
	faBuilding,
	faHandsHelping,
	faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import Section from "../shared/Section";

const useCasesData = [
	{
		icon: <FontAwesomeIcon icon={faUserShield} />,
		title: "Admins",
		description:
			"Streamline operations by managing roles, access levels, and ensuring compliance with project standards.",
		gradientColors: "from-blue-50 to-green-50",
	},
	{
		icon: <FontAwesomeIcon icon={faProjectDiagram} />,
		title: "Project Managers",
		description:
			"Gain insights through analytics, manage budgets, and ensure timely project completion with real-time updates.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
	{
		icon: <FontAwesomeIcon icon={faHardHat} />,
		title: "Site Engineers",
		description:
			"Empowered with tools to manage inventory, track materials, and oversee daily activities on-site.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		icon: <FontAwesomeIcon icon={faBuilding} />,
		title: "Erection Engineers",
		description:
			"Manage and monitor the erection process, ensuring precision and safety throughout the project.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		icon: <FontAwesomeIcon icon={faHandsHelping} />,
		title: "Laborers",
		description:
			"Experience better coordination and communication on-site, improving workflow and efficiency.",
		gradientColors: "from-red-50 to-yellow-50",
	},
	{
		icon: <FontAwesomeIcon icon={faFileContract} />,
		title: "Stakeholders",
		description:
			"Stay informed with comprehensive reports and updates, enhancing transparency and accountability.",
		gradientColors: "from-indigo-50 to-purple-50",
	},
];

const UseCases = () => {
	return (
		<Section
			sectionTitle="Built for Teams"
			sectionDescription="Discover the benefits Supersite brings to each prioritized team involved in Pre-Fab construction."
			featuresData={useCasesData}
		/>
	);
};

export default UseCases;
