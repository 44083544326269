import React from 'react';

const testimonials = [
  {
    company: 'Nagarjuna Construction Company',
    person: 'G. RamaKrishna Reddy',
    designation: 'VP North',
    project: 'AIIMS BILASPUR, HP',
    image: './images/clients/NCC.png',
    testimonial: 'AIIMS Hospital, Himachal Pradesh project is our prestigious project and we wanted the best monitoring solution for it. Project monitoring on Supersite is a delight.',
  },
  {
    company: 'DNR Group, Bengaluru',
    person: 'Prasad GM',
    designation: 'Deputy Manager',
    project: 'Commercial Complex',
    image: './images/clients/DNR.png',
    testimonial: 'The material management and financial tracking capabilities have saved us countless hours. Supersite’s seamless integration with our workflows is fantastic.',
  },
  {
    company: 'Pooja Crafted Homes, Hyderabad',
    person: 'Bala Kumar (Vice President)',
    designation: 'Vice President',
    project: 'Greenfield Residential Complex',
    image: './images/clients/POOJA.png',
    testimonial: 'From tracking daily progress to managing issues and RFIs, Supersite covers all bases. It’s made our construction management process much smoother.',
  },
  {
    company: 'Dongre PMC',
    person: 'AB Dongre',
    designation: 'Managing Director',
    project: 'Saifee Burhani Upliftment Trust, Mumbai',
    image: './images/clients/SBUT.png',
    testimonial: 'With Supersite, we have streamlined the communication with all our contractors and team members.',
  },
  {
    company: 'AMS Project Consultants, Pune',
    person: 'Rahul Malve',
    designation: 'Project Lead',
    project: 'Commercial Building',
    image: './images/clients/AMS.png',
    testimonial: 'With Supersite, we can easily monitor project progress and manage invoices. The platform has become an essential tool for our daily operations.',
  },
  {
    company: 'Zaurya RealTech Pvt Ltd, Bangalore',
    person: 'Wg Cdr Tarachand Prasad',
    designation: '(Retd., CMD)',
    project: 'Residential Apartment',
    image: './images/clients/ZAURYA.png',
    testimonial: 'Supersite’s comprehensive project management features have significantly improved our efficiency. It’s like having a complete team management solution at our fingertips.',
  },
];

const Testimonials = () => {
  return (
		<div id="testimonials" className="py-20 bg-gray-50 dark:bg-gray-900">
			<div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
				<div className="mb-12 text-center">
					<h2 className="text-3xl font-bold text-gray-800 dark:text-white md:text-4xl">
						Customer Success Stories
					</h2>
					<p className="mt-4 text-gray-600 dark:text-gray-400">
						Hear from our clients about how Supersite has
						transformed their construction management processes.
					</p>
				</div>
				<div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
					{testimonials.map((testimonial, index) => (
						<div
							key={index}
							className={`relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 transition-shadow duration-300 ease-in-out hover:shadow-lg hover:border-gray-200 dark:hover:border-gray-600`}
							style={{ minHeight: "250px" }} // Reserve space to avoid layout shift
						>
							<div className="flex items-center mb-6">
								<img
									src={testimonial.image}
									alt={`${testimonial.company} testimonial`}
									className="w-16 h-16 object-cover rounded-full ring-4 ring-indigo-200 dark:ring-indigo-600"
								/>
								<div className="ml-4">
									<p className="text-lg font-medium text-gray-800 dark:text-white">
										{testimonial.person}
									</p>
									<p className="text-sm text-gray-600 dark:text-gray-400">
										{testimonial.designation} -{" "}
										{testimonial.company}
									</p>
								</div>
							</div>
							<p className="text-gray-700 italic dark:text-gray-300">
								“{testimonial.testimonial}”
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
  );
};

export default Testimonials;
