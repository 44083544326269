import React from "react";
import {
	FaChartLine,
	FaRegClock,
	FaDollarSign,
	FaTools,
	FaHandsHelping,
	FaShieldAlt,
} from "react-icons/fa";

const benefitsData = [
	{
		icon: <FaChartLine className="text-indigo-600 w-12 h-12 mb-4" />,
		title: "Increased Efficiency",
		description:
			"Streamline your construction processes with our intuitive tools, reducing manual tasks and boosting overall productivity.",
		gradientColors: "from-indigo-50 via-purple-50 to-pink-50",
	},
	{
		icon: <FaRegClock className="text-green-600 w-12 h-12 mb-4" />,
		title: "Time Savings",
		description:
			"Reduce project timelines with automated tracking and real-time updates, keeping you on schedule and within budget.",
		gradientColors: "from-green-50 via-teal-50 to-cyan-50",
	},
	{
		icon: <FaDollarSign className="text-yellow-600 w-12 h-12 mb-4" />,
		title: "Cost Control",
		description:
			"Keep track of expenses and manage budgets more effectively with our comprehensive financial management features.",
		gradientColors: "from-yellow-50 via-orange-50 to-red-50",
	},
	{
		icon: <FaTools className="text-blue-600 w-12 h-12 mb-4" />,
		title: "Robust Tools",
		description:
			"Utilize a wide range of features designed to handle every aspect of construction management with ease and precision.",
		gradientColors: "from-blue-50 via-teal-50 to-cyan-50",
	},
	{
		icon: <FaHandsHelping className="text-teal-600 w-12 h-12 mb-4" />,
		title: "Enhanced Collaboration",
		description:
			"Improve communication and teamwork with our collaborative features, ensuring all stakeholders are on the same page.",
		gradientColors: "from-teal-50 via-cyan-50 to-blue-50",
	},
	{
		icon: <FaShieldAlt className="text-purple-600 w-12 h-12 mb-4" />,
		title: "Increased Security",
		description:
			"Protect your data with our advanced security measures, ensuring that your project information is safe and secure.",
		gradientColors: "from-purple-50 via-pink-50 to-red-50",
	},
];

const Benefits = () => {
	return (
		<div id="benefits" className="py-20">
			<div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
				<div className="mb-16 text-center">
					<h2 className="text-2xl font-bold text-gray-800 dark:text-white md:text-4xl">
						Why Choose Supersite?
					</h2>
					<p className="mt-4 text-gray-600 dark:text-gray-400">
						Discover the key benefits of using Supersite for your
						construction management needs.
					</p>
				</div>
				<div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
					{benefitsData.map((benefit, index) => (
						<div
							key={index}
							className={`relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br ${benefit.gradientColors} dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 transition-shadow duration-300 ease-in-out hover:shadow-lg hover:border-gray-200 dark:hover:border-gray-600`}
							style={{ minHeight: "250px" }} // Reserve space to avoid layout shift
						>
							{benefit.icon}
							<h3 className="text-lg font-semibold text-gray-800 dark:text-white">
								{benefit.title}
							</h3>
							<p className="mt-4 text-sm">
								{benefit.description}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Benefits;


