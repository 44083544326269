import React from "react";
import HeroSection from "../components/real-estate/HeroSection";
import FeaturesSection from "../components/real-estate/Features";
import UseCasesSection from "../components/real-estate/UseCases";
import CallToAction from "../components/shared/CallToAction";

const RealEstate = () => {
	return (
		<>
			<HeroSection />
			<FeaturesSection />
			<UseCasesSection />
			<CallToAction />
		</>
	);
};

export default RealEstate;
