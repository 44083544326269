import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactForm from "../ContactForm";

const HeroSection = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleOpenDialog = () => {
		setIsDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setIsDialogOpen(false);
	};

	return (
		<div className="relative" id="home">
			<div
				aria-hidden="true"
				className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
			>
				<div className="blur-[106px] h-56 bg-gradient-to-br from-blue-500 to-green-400 dark:from-blue-800"></div>
				<div className="blur-[106px] h-32 bg-gradient-to-r from-yellow-400 to-orange-500 dark:to-yellow-600"></div>
			</div>
			<div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
				<div className="flex flex-col lg:flex-row items-center py-36">
					{/* Left Content Section */}
					<div className="lg:w-1/2 text-center lg:text-left">
						<h1 className="text-gray-900 dark:text-white font-bold text-4xl md:text-5xl xl:text-6xl">
							Transform Your Infrastructure
							Projects with{" "}
							<span className="text-blue-600 dark:text-white">
								Supersite
							</span>
						</h1>
						<p className="mt-8 text-gray-700 dark:text-gray-300">
							From roadways to bridges,
							Supersite helps you manage the
							complexities of infrastructure
							development. Track progress,
							handle materials, and ensure
							smooth coordination at every
							phase.
						</p>
						<div className="mt-8 flex flex-wrap justify-center lg:justify-start gap-y-4 gap-x-6">
							<button
								onClick={handleOpenDialog}
								className="relative flex h-11 w-full items-center justify-center px-10 before:absolute before:inset-0 before:rounded-full before:bg-blue-600 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
							>
								<span className="relative text-base font-semibold text-white">
									Contact Us
								</span>
							</button>

							<Link
								to="https://calendly.com/supersite"
								target="_blank"
								className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-gray-200 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
							>
								<span className="relative text-base font-semibold text-gray-900 dark:text-gray-800">
									Request a Demo
								</span>
							</Link>
						</div>
					</div>
					{/* Right Image Section */}
					<div className="lg:w-1/2 mt-8 ml-4 lg:mt-0">
						<img
							src="/images/solutions/infrastructure.jpg"
							alt="Infrastructure Illustration"
							className="w-full h-auto rounded-lg shadow-lg"
							style={{
								maxHeight: "500px",
								objectFit: "cover",
							}} // Set a fixed max height to avoid shifts
						/>
					</div>
				</div>
			</div>
			{/* Dialog */}
			{isDialogOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg p-6 max-w-sm w-full relative">
						<button
							onClick={handleCloseDialog}
							className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<ContactForm />
					</div>
				</div>
			)}
		</div>
	);
};

export default HeroSection;
