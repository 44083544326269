import React from "react";

const Card = ({ icon, title, description, gradientColors }) => {
	return (
		<div
			className={`relative p-8 border border-gray-100 dark:border-gray-700 rounded-3xl bg-gradient-to-br ${gradientColors} dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 text-gray-600 dark:text-gray-300 transition-shadow duration-300 ease-in-out hover:shadow-lg hover:border-gray-200 dark:hover:border-gray-600`}
			style={{ minHeight: "250px" }} // Reserve space to avoid layout shift
		>
			<div className="text-gray-800 text-5xl mx-auto mb-6">{icon}</div>
			<h3 className="text-xl font-bold text-gray-800">{title}</h3>
			<p className="mt-4 text-gray-700">{description}</p>
		</div>
	);
};

export default Card;


