import React from "react";
import {
	FaPhoneAlt,
	FaEnvelope,
	FaMapMarkerAlt,
	FaFacebookF,
	FaLinkedinIn,
	FaInstagram,
	FaMedium,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer className="bg-gray-900 text-gray-300 py-12">
			<div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
				<div className="grid grid-cols-1 md:grid-cols-4 gap-8">
					{/* Company Info */}
					<div>
						<h3 className="text-xl font-bold text-white">
							Supersite
						</h3>
						<p className="mt-4">
							Supersite provides comprehensive construction
							management tools to streamline your projects and
							enhance collaboration. Optimize every aspect of your
							construction workflow with ease.
						</p>
						<div className="mt-6 flex gap-4">
							<Link
								to="https://linkedin.com/company/supersite-app"
								className="text-gray-300 hover:text-white"
							>
								<FaLinkedinIn size={20} />
							</Link>
							<Link
								to="https://www.facebook.com/app.supersite/"
								className="text-gray-300 hover:text-white"
							>
								<FaFacebookF size={20} />
							</Link>
							<Link
								to="https://www.instagram.com/supersite.app/"
								className="text-gray-300 hover:text-white"
							>
								<FaInstagram size={20} />
							</Link>
							<Link
								to="https://blog.supersite.app/"
								className="text-gray-300 hover:text-white"
							>
								<FaMedium size={20} />
							</Link>
						</div>
					</div>

					{/* Quick Links */}
					<div>
						<h3 className="text-xl font-bold text-white">
							Quick Links
						</h3>
						<ul className="mt-4 space-y-2">
							<li>
								<Link to="/" className="hover:text-indigo-400">
									Home
								</Link>
							</li>
							<li>
								<Link
									to="/contact"
									className="hover:text-indigo-400"
								>
									Contact Us
								</Link>
							</li>
							<li>
								<Link
									to="https://blog.supersite.app"
									className="hover:text-indigo-400"
								>
									Blog
								</Link>
							</li>
						</ul>
					</div>

					{/* Industry Pages */}
					<div>
						<h3 className="text-xl font-bold text-white">
							Industries
						</h3>
						<ul className="mt-4 space-y-2">
							<li>
								<Link
									to="/industries/real-estate"
									className="hover:text-indigo-400"
								>
									Real Estate
								</Link>
							</li>
							<li>
								<Link
									to="/industries/pre-fab"
									className="hover:text-indigo-400"
								>
									Steel Structure
								</Link>
							</li>
							<li>
								<Link
									to="/industries/infrastructure"
									className="hover:text-indigo-400"
								>
									Infrastructure
								</Link>
							</li>
							
						</ul>
					</div>

					{/* Contact Information */}
					<div>
						<h3 className="text-xl font-bold text-white">
							Contact Us
						</h3>
						<div className="mt-4 flex items-center gap-4">
							<FaPhoneAlt className="text-indigo-500" size={20} />
							<p>(+91) 738-8890-554</p>
						</div>
						<div className="mt-2 flex items-center gap-4">
							<FaEnvelope className="text-indigo-500" size={20} />
							<p>
								<Link
									to="mailto:hello@supersite.app"
									className="hover:text-indigo-400"
								>
									hello@supersite.app
								</Link>
							</p>
						</div>
						<div className="mt-2 flex items-center gap-4">
							<FaMapMarkerAlt
								className="text-indigo-500"
								size={20}
							/>
							<p>Mumbai, Maharashtra, India</p>
						</div>
					</div>
				</div>

				<div className="mt-12 border-t border-gray-700 pt-6 text-center">
					<p className="text-gray-500 text-sm">
						&copy; {new Date().getFullYear()} Supersite. All rights
						reserved.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
