import React from "react";
import Section from "../shared/Section";

const benefitsData = [
	{
		title: "Enhanced Efficiency",
		description:
			"Streamline your inventory and erection management processes, reducing downtime and maximizing productivity.",
		icon: "⚙️", // Replace with appropriate icons
		gradientColors: "from-blue-50 to-green-50",
	},
	{
		title: "Real-Time Updates",
		description:
			"Stay informed with instant updates on material status, ensuring timely decisions and actions.",
		icon: "📈", // Replace with appropriate icons
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		title: "Improved Collaboration",
		description:
			"Facilitate better communication between site engineers, project managers, and admin for seamless project execution.",
		icon: "🤝", // Replace with appropriate icons
		gradientColors: "from-yellow-50 to-orange-50",
	},
	{
		title: "Comprehensive Reporting",
		description:
			"Access detailed daily progress reports (DPR) at various levels—building, project, zone, and national.",
		icon: "📊", // Replace with appropriate icons
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		title: "Cost Management",
		description:
			"Optimize budgets and track expenditures to ensure projects are delivered within financial constraints.",
		icon: "💰", // Replace with appropriate icons
		gradientColors: "from-red-50 to-yellow-50",
	},
	{
		title: "Risk Mitigation",
		description:
			"Identify potential risks early through data analysis, allowing for proactive management and solutions.",
		icon: "⚠️", // Replace with appropriate icons
		gradientColors: "from-gray-50 to-blue-50",
	},
];

const Benefits = () => {
	return (
		<Section
			sectionTitle="Benefits for Pre-Fab Construction"
			sectionDescription=""
			featuresData={benefitsData}
		/>
	);
};

export default Benefits;
