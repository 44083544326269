import React from "react";
import {
	FaRoad,
	FaBuilding,
	FaClipboardCheck,
	FaChartLine,
	FaUsersCog,
	FaCogs,
} from "react-icons/fa";
import Section from "../shared/Section";

const featuresData = [
	{
		title: "Project Planning and Scheduling",
		icon: <FaRoad />,
		description:
			"Plan and schedule project timelines efficiently, ensuring all tasks are aligned with overall project goals.",
		gradientColors: "from-blue-50 to-green-50",
	},
	{
		title: "Resource Management",
		icon: <FaBuilding />,
		description:
			"Manage materials, labor, and equipment effectively to optimize resource allocation across projects.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
	{
		title: "Daily Progress Tracking",
		icon: <FaClipboardCheck />,
		description:
			"Monitor daily progress with real-time updates, ensuring projects stay on track and within budget.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		title: "Risk Management",
		icon: <FaUsersCog />,
		description:
			"Identify and mitigate potential risks with proactive management strategies tailored to infrastructure projects.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		title: "Compliance and Reporting",
		icon: <FaCogs />,
		description:
			"Ensure adherence to regulations with comprehensive reporting features that track compliance metrics.",
		gradientColors: "from-indigo-50 to-purple-50",
	},
	{
		title: "Analytics and Insights",
		icon: <FaChartLine/>,
		description:
			"Utilize data analytics to gain insights into project performance and make informed decisions for future projects.",
		gradientColors: "from-red-50 to-yellow-50",
	},
];

const Features = () => {
	return (
		<Section
			sectionTitle="Key Features for Infrastructure Management"
			sectionDescription="Discover how Supersite optimizes infrastructure projects with advanced management tools and real-time insights."
			featuresData={featuresData}
		/>
	);
};



export default Features;


