import React from 'react'
import HeroSection from '../components/infrastructure/HeroSection';
import Features from '../components/infrastructure/Features';
import UseCases from '../components/infrastructure/UseCases';
import Benefits from '../components/infrastructure/Benefits';
import CallToAction from '../components/shared/CallToAction';

const Infrastructure = () => {
  return (
		<div>
			<HeroSection />
			<Features />
			<Benefits />
			<UseCases />
			<CallToAction />
		</div>
  );
}

export default Infrastructure