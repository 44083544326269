import React from "react";
import HeroSection from "../components/steel-structure/HeroSection";
import CallToAction from "../components/shared/CallToAction";
import Features from "../components/steel-structure/Features";
import Benefits from "../components/steel-structure/Benefits";
import UseCases from "../components/steel-structure/UseCases";

const SteelStructure = () => {
	return (
		<>
			<HeroSection />
			<Features />
			<Benefits />
			<UseCases/>
			<CallToAction />
		</>
	);
};

export default SteelStructure;
