// src/firebase/config.js

import {initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDp4Oaf8vXTpVubG_v8blIeyAB6VQTEyZg",
  authDomain: "supersite-8f7be.firebaseapp.com",
  projectId: "supersite",
  storageBucket: "supersite.appspot.com",
  messagingSenderId: "778759844234",
  appId: "1:778759844234:web:4929837f1a623fd30d9873"
};



const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
