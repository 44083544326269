import React, { useState } from 'react';
import ContactForm from '../ContactForm';

const PricingTeaser = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
		<div
			className="relative bg-gray-100 dark:bg-gray-900 py-20"
			id="pricing"
		>
			<div className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-20 dark:opacity-10">
				<div className="blur-[106px] h-56 bg-gradient-to-br from-blue-500 to-green-400 dark:from-blue-800"></div>
				<div className="blur-[106px] h-32 bg-gradient-to-r from-yellow-400 to-orange-500 dark:to-yellow-600"></div>
			</div>
			<div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
				<div className="text-center">
					<h2 className="text-gray-900 dark:text-white font-bold text-3xl md:text-4xl xl:text-5xl">
						Simple & Flexible Pricing
					</h2>
					<p className="mt-4 text-gray-700 dark:text-gray-300">
						At Supersite, we offer flexible
						pricing options to suit your needs.
						Choose between paying per project
						per month or a full access annual
						plan. Our aim is to provide you with
						the best value and scalability for
						your construction management needs.
					</p>
					<div className="mt-8 flex flex-col sm:flex-row justify-center gap-y-4 gap-x-6">
						<button
							onClick={handleOpenDialog}
							className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-blue-600 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
						>
							<span className="relative text-base font-semibold text-white">
								Contact Sales
							</span>
						</button>
						{/* <Link
              to="/pricing"
              className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-gray-200 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
            >
              <span className="relative text-base font-semibold text-gray-900 dark:text-gray-800">
                View Pricing Details
              </span>
            </Link> */}
					</div>
				</div>
			</div>

			{/* Dialog */}
			{isDialogOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 rounded-lg shadow-lg p-6 max-w-sm w-full relative">
						<button
							onClick={handleCloseDialog}
							className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<ContactForm />
					</div>
				</div>
			)}
		</div>
  );
};

export default PricingTeaser;
