import React from "react";
import {
	FaWarehouse,
	FaHardHat,
	FaClipboardCheck,
	FaChartLine,
	FaUsersCog,
	FaCogs,
} from "react-icons/fa";
import Section from "../shared/Section";

const prefabFeaturesData = [
	{
		icon: <FaWarehouse />,
		title: "Inventory Management",
		description:
			"Track materials on-site, manage inventory, and monitor statuses for efficient resource allocation.",
		gradientColors: "from-blue-50 to-green-50",
	},
	{
		icon: <FaHardHat />,
		title: "Erection Management",
		description:
			"Manage the erection status of each part with precision and monitor erectable vs. non-erectable parts.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
	{
		icon: <FaClipboardCheck />,
		title: "Daily Progress Reports (DPR)",
		description:
			"Get comprehensive DPRs at the building, project, zone, and India levels for full transparency.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		icon: <FaUsersCog />,
		title: "Role & Access Management",
		description:
			"Assign roles and permissions to site engineers, project managers, and admins for efficient oversight.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		icon: <FaCogs />,
		title: "Project Setup & Management",
		description:
			"Easily set up and manage projects, buildings, and zones to ensure streamlined operations.",
		gradientColors: "from-indigo-50 to-purple-50",
	},
	{
		icon: <FaChartLine />,
		title: "Real-Time Notifications & Analytics",
		description:
			"Receive instant updates and access detailed analytics to support informed decision-making.",
		gradientColors: "from-red-50 to-yellow-50",
	},
];

const Features = () => {
	return (
		<Section
			sectionTitle="Key Features for Prefabricated Construction"
			sectionDescription="Discover how Supersite simplifies your construction processes with tailored features for the Pre-Fab industry."
			featuresData={prefabFeaturesData}
		/>
	);
};

export default Features;
