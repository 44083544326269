import React from "react";
import Card from "../shared/Card"; // Assuming the Card component is in the same directory

const Section = ({ sectionTitle, sectionDescription, featuresData }) => {
	return (
		<div className="bg-gradient-to-b from-blue-50 via-white to-gray-50 dark:bg-gray-900 py-20">
			<div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
				<div className="text-center">
					<h2 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white mb-4">
						{sectionTitle}
					</h2>
					<p className="mt-4 text-gray-700 dark:text-gray-300">
						{sectionDescription}
					</p>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
					{featuresData.map((feature, index) => (
						<Card
							key={index}
							icon={feature.icon}
							title={feature.title}
							description={feature.description}
							gradientColors={feature.gradientColors}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default Section;
