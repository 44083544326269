import React from "react";
import Section from "../shared/Section";
import { FaBuilding, FaFileContract, FaHandsHelping, FaHardHat, FaProjectDiagram, FaUserShield } from "react-icons/fa";

const useCasesData = [
	{
		icon: <FaProjectDiagram />,
		title: "Project Managers",
		description:
			"Utilize real-time data to manage timelines, budgets, and resources, ensuring projects are completed on schedule.",
		gradientColors: "from-blue-50 to-green-50", // Add gradient colors
	},
	{
		icon: <FaBuilding />,
		title: "Contractors",
		description:
			"Coordinate resources and manage subcontractors efficiently to ensure smooth project execution.",
		gradientColors: "from-purple-50 to-pink-50",
	},
	{
		icon: <FaUserShield />,
		title: "Consultants",
		description:
			"Provide expert insights and recommendations to ensure compliance and optimize project performance.",
		gradientColors: "from-teal-50 to-cyan-50",
	},
	{
		icon: <FaFileContract />,
		title: "Regulators",
		description:
			"Access necessary documentation and reports to ensure compliance with industry standards and regulations.",
		gradientColors: "from-indigo-50 to-purple-50",
	},
	{
		icon: <FaHandsHelping />,
		title: "Architects",
		description:
			"Utilize design tools and project insights to create innovative structures while ensuring design integrity and compliance.",
		gradientColors: "from-red-50 to-yellow-50",
	},
	{
		icon: <FaHardHat />,
		title: "Site Supervisors",
		description:
			"Monitor daily operations, ensuring safety and efficiency on-site while coordinating with various teams.",
		gradientColors: "from-yellow-50 to-orange-50",
	},
];

const UseCases = () => {
	return (
		<Section
			sectionTitle="Built for all stakeholders"
			sectionDescription="Explore how Supersite supports each team involved in infrastructure projects."
			featuresData={useCasesData}
		/>
	);
};

export default UseCases;
